import Vue from "vue";
import App from "./App.vue";
import router from "./plugins/router";
import { store } from "@/plugins/store";

import Buefy from "buefy";
import IdleVue from "idle-vue";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import VueGtag from "vue-gtag";

// Import the plugin here
import { AuthPlugin } from "./plugins/auth";
import { far } from "@fortawesome/free-regular-svg-icons";

/*
I don't know why, but there is something that overrides the default link color on dev/prod
I think it has something to do with a Tailwind dependency called modern-normalize
Anyway, to make our local environment consistent with dev/prod, I've explicitly specified
link color in myStyles.scss. 

ALSO the order here matters. To keep dev consistent with local, the Bulma file needs to go last 
here. Once again, don't ask me why
*/
import "./index.css"; // Tailwind
import "buefy/dist/buefy.css";
require("./myStyles.scss"); // Bulma

library.add(fas);
library.add(far);
Vue.component("vue-fontawesome", FontAwesomeIcon);

Vue.use(Buefy, {
  defaultIconComponent: "vue-fontawesome",
  defaultIconPack: "fas",
  customIconPacks: {
    fas: {
      sizes: {
        default: "lg",
        "is-small": "1x",
        "is-medium": "2x",
        "is-large": "3x",
      },
      iconPrefix: "",
    },
    far: {
      sizes: {
        default: "lg",
        "is-small": "1x",
        "is-medium": "2x",
        "is-large": "3x",
      },
      iconPrefix: "",
    },
  },
});

// Install the authentication plugin here
Vue.use(AuthPlugin, {
  store: store,
});

Vue.config.productionTip = false;

Vue.use(VueGtag, { config: { id: process.env.VUE_APP_GA_ID } }, router);

import VueCascaderSelect from "vue-cascader-select";

Vue.use(VueCascaderSelect);

import VueSlideoutPanel from "vue2-slideout-panel";
Vue.use(VueSlideoutPanel);

//@ts-ignore
import LegoSidePanel from "@/components/kit-builder/LegoSidePanel";
Vue.component("LegoSidePanel", LegoSidePanel);

//@ts-ignore
import ParamNoteSidePanel from "@/components/kit-builder/ParamNoteSidePanel";
Vue.component("ParamNoteSidePanel", ParamNoteSidePanel);

//@ts-ignore
import ResourceSidePanel from "@/components/resources/ResourceSidePanel.vue";
Vue.component("ResourceSidePanel", ResourceSidePanel);

const eventsHub = new Vue();

Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store: store,
  idleTime: 60 * 60 * 1000, // min * secs * ms
  startAtIdle: false,
});

new Vue({
  router,
  store: store,
  render: (h) => h(App),
}).$mount("#app");
