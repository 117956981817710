import axios from "axios";
import qs from "qs";
import { getAuthInstance } from "@/plugins/auth";
import snakecaseKeys from "snakecase-keys";
import {
  lfirst,
  oget,
  oKeysToCamel,
  Stack,
  okeys,
  Queue,
} from "@/brains/flang";
import { store } from "@/plugins/store";
import { AxiosRequestHeaders } from "axios";
import {
  ApiRequestOptions,
  ApiRequest,
  HttpMethod,
} from "@/types/general-flank-types";
import { zz } from "@/brains/malf";

function getAuthorizedHeader(accessToken: string): AxiosRequestHeaders {
  if (getAuthInstance().idaas === "demo") {
    return {
      "X-DemoUserId": accessToken,
    };
  } else if (!getAuthInstance().isAuthenticated) {
    return {
      "X-Public": true,
    };
  } else {
    return {
      Authorization: `Bearer ${accessToken}`,
    };
  }
}

function paramsSerializer(params: any) {
  return qs.stringify(params, {
    arrayFormat: "repeat",
    skipNulls: true,
  });
}

function customEncodeURIComponent(path: string): string {
  const placeholder = "__FLANKSLASH__";

  // Step 1: Replace all slashes with a placeholder.
  let tempStr = path.replace(/\//g, placeholder);

  // Step 2: Encode the string.
  tempStr = encodeURIComponent(tempStr);

  // Step 3: Replace the placeholder with slashes.
  return tempStr.replace(new RegExp(placeholder, "g"), "/");
}

function getUrl(
  path: string,
  orgPath = false,
  orgId: null | string = null
): string {
  if (orgId) {
    return `${
      process.env.VUE_APP_API_HOSTNAME
    }/v1/${orgId}/${customEncodeURIComponent(path)}`;
  } else if (orgPath) {
    return `${process.env.VUE_APP_API_HOSTNAME}/v1/${
      store.state.activeOrg?.orgId
    }/${customEncodeURIComponent(path)}`;
  }
  return `${process.env.VUE_APP_API_HOSTNAME}/v1/${customEncodeURIComponent(
    path
  )}`;
}
async function get(
  path: string,
  {
    query = {},
    orgPath = false,
    timeout = 0,
    orgId = "",
    debug = false,
  }: ApiRequestOptions = {}
): Promise<any> {
  const accessToken = await getAuthInstance().getTokenSilently();
  const config = {
    headers: getAuthorizedHeader(accessToken),
    params: query,
    paramsSerializer: paramsSerializer,
    timeout: timeout,
  };
  const url = getUrl(path, orgPath, orgId);
  const resp = await axios.get(url, config);
  if (debug) {
    zz({ url });
    zz(config);
    zz(resp);
    zz(resp.data);
  }
  return oKeysToCamel(resp.data);
}
async function _delete(
  path: string,
  { orgPath = false, timeout = 0, orgId = "" }: ApiRequestOptions = {}
): Promise<any> {
  const accessToken = await getAuthInstance().getTokenSilently();
  const resp = await axios.delete(getUrl(path, orgPath, orgId), {
    headers: getAuthorizedHeader(accessToken),
    timeout: timeout,
  });
  return oKeysToCamel(resp.data);
}
async function post(
  path: string,
  body: any,
  {
    isList = false,
    orgPath = false,
    timeout = 0,
    orgId = "",
  }: ApiRequestOptions = {}
): Promise<any> {
  const accessToken = await getAuthInstance().getTokenSilently();
  const resp = await axios.post(
    getUrl(path, orgPath, orgId),
    snakecaseKeys(body),
    {
      headers: getAuthorizedHeader(accessToken),
      params: isList ? { list: true } : {},
      paramsSerializer: paramsSerializer,
      timeout: timeout,
    }
  );
  return oKeysToCamel(resp.data);
}

const patchStacks = {} as {
  [uniqueKey: string]: {
    stack: Stack<Promise<any>>;
    currentPromiseQueue: Queue<Promise<any>>;
  };
};
async function patch(
  path: string,
  body: any,
  {
    orgPath = false,
    timeout = 0,
    enforceOrder = false,
    orgId = "",
  }: ApiRequestOptions = {}
): Promise<any> {
  const accessToken = await getAuthInstance().getTokenSilently();
  try {
    const url = getUrl(path, orgPath, orgId);
    const promise = axios.patch(url, snakecaseKeys(body), {
      headers: getAuthorizedHeader(accessToken),
      timeout: timeout,
    });
    if (enforceOrder) {
      if (okeys(body).length > 1 || okeys(body).length == 0) {
        throw Error("enforceOrder only works with single key body");
      }

      const key = lfirst(okeys(body));
      const uniqueKey = `${url}::${key}`;

      if (!(uniqueKey in patchStacks)) {
        patchStacks[uniqueKey] = {
          stack: new Stack<Promise<any>>(),
          currentPromiseQueue: new Queue<Promise<any>>(),
        };
      }

      if (patchStacks[uniqueKey].currentPromiseQueue.isEmpty()) {
        // if nothing is in progress, fire the request
        patchStacks[uniqueKey].currentPromiseQueue.enqueue(promise);
        // console.log("current empty, firing with ", body);
        try {
          const resp = await promise;
          return oKeysToCamel(resp.data);
        } finally {
          patchStacks[uniqueKey].currentPromiseQueue.dequeue();
        }
      } else {
        // if there is a request in progress, add to stack and wait for that request to resolve
        patchStacks[uniqueKey].stack.push(promise);
        const size0 = patchStacks[uniqueKey].stack.size();
        // console.log("current not null, pushing to stack", size0);
        patchStacks[uniqueKey].currentPromiseQueue
          .front()
          ?.then((inProgressResp) => {
            const sizeT = patchStacks[uniqueKey].stack.size();
            if (size0 == sizeT) {
              // if this is still the most recent request to be added to the stack, fire it
              // console.log("I'm top of stack, refiring with", body);
              patchStacks[uniqueKey].currentPromiseQueue.enqueue(promise);
              return promise
                .then((resp) => {
                  return oKeysToCamel(resp.data);
                })
                .finally(() => {
                  patchStacks[uniqueKey].currentPromiseQueue.dequeue();
                });
            } else {
              // if this request is now buried in the stack, return the response from the request that is currently in progress
              // console.log(
              //   "I'm buried in stack, return",
              //   inProgressResp.data.name
              // );
              return oKeysToCamel(inProgressResp.data);
            }
          })
          .catch((e) => {
            // if the in-progress request fails, fire the next request in the stack
            // console.log(
            //   "In progress failed and I'm top of stack, refiring with",
            //   body
            // );
            patchStacks[uniqueKey].currentPromiseQueue.enqueue(promise);
            return promise
              .then((resp) => {
                return oKeysToCamel(resp.data);
              })
              .finally(() => {
                patchStacks[uniqueKey].currentPromiseQueue.dequeue();
              });
          });
      }
    } else {
      const resp = await promise;
      return oKeysToCamel(resp.data);
    }
  } catch (error: any) {
    if (oget(error, "response.status") == 409) {
      console.log(`409 error on ${path}`);
      return;
    } else {
      throw error;
    }
  }
}
async function put(
  path: string,
  body: any,
  { orgPath = false, timeout = 0, orgId = "" }: ApiRequestOptions = {}
): Promise<any> {
  const accessToken = await getAuthInstance().getTokenSilently();
  const resp = await axios.put(
    getUrl(path, orgPath, orgId),
    snakecaseKeys(body),
    {
      headers: getAuthorizedHeader(accessToken),
      timeout: timeout,
    }
  );
  return oKeysToCamel(resp.data);
}

function isApiRequest(data: any) {
  return data && data.method && data.path;
}

async function createRequest(r: ApiRequest): Promise<any> {
  let call;
  if (r.method == HttpMethod.GET) {
    call = get(r.path, r.options);
  } else if (r.method == HttpMethod.POST) {
    call = post(r.path, r.body, r.options);
  } else if (r.method == HttpMethod.PATCH) {
    call = patch(r.path, r.body, r.options);
  } else if (r.method == HttpMethod.DELETE) {
    call = _delete(r.path, r.options);
  } else if (r.method == HttpMethod.PUT) {
    call = put(r.path, r.body, r.options);
  } else {
    throw Error("Invalid method in api.createRequest()");
  }
  return call.then((resp) => {
    if (r.callback) {
      const callbackResp = r.callback(resp);
      if (isApiRequest(callbackResp)) {
        return createRequest(callbackResp);
      } else {
        // if (callbackResp.parallelRequests) {
        //   // I use this in the sync chain, for example
        //   // POST sync => GET lego => update schemas and return kitDoll / update those schemas in DB
        //   processRequests(callbackResp.parallelRequests);
        // }
        return callbackResp;
      }
    } else {
      return resp;
    }
  });
}
async function processRequests(requests: ApiRequest[]): Promise<any> {
  const promises = requests.map(createRequest);
  const responses = await Promise.all(promises);
  return responses;
}
async function processRequest(request: ApiRequest): Promise<any> {
  return createRequest(request);
}
export default {
  getUrl,
  get,
  post,
  patch,
  delete: _delete,
  put,
  processRequests,
  processRequest,
};
