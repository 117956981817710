import { SyncStageI, SyncStageII } from "./api-schema";
import { ERunGET } from "./api-schema";
import { LegoDoll } from "../shelves/kitShelf";
import _ from "lodash";

export interface ParamOption {
  display: string;
  display2: string;
  color: string;
  icon: string;
  value: string | number | Date;
}

export interface FlatSchemaElement {
  componentId: "textarea" | "cardMultiselect";
  colSpan: 1 | 2 | 3 | 4 | 5 | 6;
  value: string | number | boolean | Date | null;
  key: string;
  path: string;
  defaultAmbiguousInput: HowToHandleEmptyInput | null;
  customLabel: string | null; // maps to old displayValue
}

export interface FlatSchemaElementInput {
  componentId: "textarea" | "cardMultiselect";
  colSpan?: 1 | 2 | 3 | 4 | 5 | 6;
  value: string | number | boolean | Date | null;
  key: string;
  path: string;
  defaultAmbiguousInput?: HowToHandleEmptyInput | null;
  customLabel?: string | null; // maps to old displayValue
}
export function flatSchemaElement({
  componentId,
  colSpan = 6,
  value,
  key,
  path,
  defaultAmbiguousInput = null,
  customLabel = null,
}: FlatSchemaElementInput): FlatSchemaElement {
  return {
    componentId,
    colSpan,
    value,
    key,
    path,
    defaultAmbiguousInput,
    customLabel,
  };
}
export interface DisplayValue {
  display: string;
  value: string | number | Date | null;
}

export interface InternalFilterConstructor {
  options: DisplayValue[] | null;
  currentValue: any;
  computedFilter: (data: any) => boolean;
  pillDisplay: () => string;
  iconLeft: string;
  onClose: () => void;
}
export class InternalFilter {
  options: DisplayValue[] | null;
  currentValue: any;
  computedFilter: (data: any) => boolean;
  pillDisplay: () => string;
  iconLeft: string;
  onClose: () => void;

  constructor({
    options,
    currentValue,
    computedFilter,
    pillDisplay,
    iconLeft,
    onClose,
  }: InternalFilterConstructor) {
    this.options = options;
    this.currentValue = currentValue;
    this.computedFilter = computedFilter.bind(this);
    this.pillDisplay = pillDisplay.bind(this);
    this.iconLeft = iconLeft;
    this.onClose = onClose.bind(this);
  }
}

export interface InternalFilterCollection {
  [key: string]: InternalFilter;
}

export interface BulkRunPayload {
  legoPath: string;
  runramList: any[];
}
export enum KitVisibility {
  justme = "justme",
  public = "public",
  org = "org",
  store = "store",
}
export enum ExecutionEnv {
  nol = "nol",
  sql = "sql",
  markdown = "markdown",
  variable = "variable",
}
export interface Invitation {
  orgName: string;
  fromOrgId: string;
  toEmail: string;
  fromUserId: string;
  role: string;
  fromName: string;
  fromNickname: string;
  fromEmail: string;
  status: string;
}

export class FlankUser {
  userId: string;
  nickname?: string;
  name?: string;
  email?: string;
  pictureUrl: string;

  constructor(user: any) {
    this.userId = user.userId;
    this.nickname = user?.nickname ?? null;
    this.name = user?.name ?? null;
    this.email = user?.email ?? null;
    this.pictureUrl = user?.pictureUrl ?? null;
  }
}

export enum ColumnsKeys {
  name = "name",
  lastRun = "lastRun",
  status = "status",
  runners = "runners",
  // sharers = "sharers",
  runButtons = "runButtons",
}
export interface SchemaConstraints {
  multipleOf?: number | null;
  maximum?: string | null;
  exclusiveMaximum?: boolean | null;
  minimum?: string | null;
  exclusiveMinimum?: boolean | null;
  maxLength?: number | null;
  minLength?: number | null;
  pattern?: string | null;
  maxItems?: number | null;
  minItems?: number | null;
  uniqueItems?: boolean | null;
  maxProperties?: number | null;
  minProperties?: number | null;
  required?: boolean | null;
  readOnly?: boolean | null;
  writeOnly?: boolean | null;
  nullable?: boolean | null;
  enum?: any[] | null;
  validationMessage?: string | null;
  step?: string | null;
}

export type GeneralKVPair = {
  key: any;
  value: any;
};

export type LegoSchemaElement = {
  // IMPORTANT NOTE: See params.initElement if you're making any changes here
  // sync, global across orgs, lego-level, (in most settings) read-only
  key: string;
  typeSystem: string; // should be deprecated at some point...
  typeId: string;
  constraints: SchemaConstraints;
  jsonType: string; // This should be calculated... isn't used in backend at all
  component: string;
  inputType: string; // These two should be consolidated
  objectPropertySchemas?: KitRunSchemaElement[] | LegoSchemaElement[] | null;
  listElementSchema?: LegoSchemaElement | null;
  displayValue: string | null;
  groupHeader: string | null;
  componentWidth: string | null;
  order: number;
};
export enum HowToHandleEmptyInput {
  emptyString = "emptyString",
  explicitNull = "explicitNull",
  omit = "omit",
}
export type InstanceSchemaElementOnly = {
  // IMPORTANT NOTE: See params.initElement if you're making any changes here
  // specific to an instance in a kit, (in most settings) read/write
  defaultValue: string | number | boolean | null | Date;
  defaultAmbiguousInput: HowToHandleEmptyInput | null;
  useDefaultValue: boolean;
  paramSender: ParamSender | null;
  lockDefaultValue: boolean;
  hidden: boolean;
  note: string;
  tooltip: string;
  manuallyAdded: boolean;
  uuid: string; // this gets created on initElement
  // it does NOT get created on sync (I don't think we really need it on the backend)
  // this avoided having to write a data migration for the schema column, which is a little hairy
  // it's unique for a session, but we don't save it back to the database
  legoInstanceInputId: number | null;
  kiliInputId: number | null;
};
export type InstanceSchemaElement = LegoSchemaElement &
  InstanceSchemaElementOnly;
export type KitRunSchemaElementOnly = {
  // IMPORTANT NOTE: See params.initElement and kitRunSchemaElementFields if you're making any changes here
  listElements: KitRunSchemaElement[] | null;
  value: string | number | boolean | Date | null;
  isValid: boolean;
};
export type PartialKitRunSchemaElement = Partial<KitRunSchemaElement>;
export type KitRunSchemaElement = KitRunSchemaElementOnly &
  InstanceSchemaElement;

export enum UIType {
  dropdown = "dropdown",
  radio = "radio",
  checkboxes = "checkboxes",
  input = "input",
  multiselect = "multiselect",
}
export enum ReferenceType {
  fullInput = "fullInput",
  column = "column",
  columnArray = "columnArray",
  schemaElement = "schemaElement",
}
export interface ParamSender {
  path: string;
  uiType: UIType;
  referenceType: ReferenceType; // "schemaElement" is for variables
  references: {
    valueColField?: string | null;
    displayColField?: string | null;
    elementPath?: string | null; // I'm making this field optional, along with valueColField and displayColField
    // because I don't want to add `elementPath` to the existing dropdowns, and I don't want include
    // an empty `valueColField` or `displayColField` in variable references
  };
}
export interface DropdownDoll {
  valueColField: string;
  displayColField: string;
  name: string;
  ephemeralAiId: number;
  senderKitId: number;
  olId: string;
  directoryId: number | null;
  directoryName: string | null;
}
export interface ReceiveSidePanelResult {
  savePopulatingInstance: boolean;
  addLegoToKitInstances: boolean;
  senderKitInstance: KitInstanceDoll;
  senderLegoInstance: LegoDoll;
  receiverInstance: LegoDoll;
  paramSenderUIType: "dropdown" | "radio" | "checkboxes" | "input";
  paramSenderReferenceType: "fullInput" | "column" | "columnArray";
  selectedValueCol: string;
  selectedDisplayCol: string;
  senderMostRecentRunWrapper: MostRecentRunWrapper;
}
export interface UserPerms {
  legoId: number;
  users: any[];
}

export interface UserPermsKit {
  kitId: number;
  users: any[];
}

export enum NextStep {
  report_bug = "report_bug",
  timeout_try_again = "timeout_try_again",
  admin_fix_credential = "admin_fix_credential",
  admin_fix_storage = "admin_fix_storage",
  admin_resync = "admin_resync",
  admin_goto_aws_console = "admin_goto_aws_console",
  admin_goto_azure_portal = "admin_goto_azure_portal",
  admin_goto_openapi_spec = "admin_goto_openapi_spec",
  admin_check_nos_db = "admin_check_nos_db",
  admin_check_diagnostics = "admin_check_diagnostics",
}
export interface ErrorOut {
  title: string;
  message: string;
  trace: string;
  next_steps: NextStep[];
}

export enum SyncStageIState {
  posting_sync_stage_i = "posting_sync_stage_i",
  error = "error",
  posting_sync_stage_ii = "posting_sync_stage_ii",
  completed = "completed",
}
export enum SyncStageIIState {
  posting = "posting",
  error = "error",
  completed = "completed",
}
export enum SyncType {
  addition = "addition",
  deletion = "deletion",
  undeletion = "undeletion",
  update = "update",
}
export interface SyncStageIIDoll {
  id: string | number;
  name: string;
  isHidden: boolean;
  state: SyncStageIIState;
  response: SyncStageII;
  syncType: SyncType;
}
export interface TwoStageSyncWrapper {
  credsId: number;
  displayName: string;
  state: SyncStageIState;
  response: SyncStageI;
  additions: Record<string | number, SyncStageIIDoll>;
  deletions: Record<string | number, SyncStageIIDoll>;
  updates: Record<string | number, SyncStageIIDoll>;
  undeletions: Record<string | number, SyncStageIIDoll>;
}

export interface RunResultDoll {
  runResult: ERunGET;
  frontendState: string;
  runrams: any;
  runOrder: number;
}

export interface MostRecentRunWrapper {
  instanceId: number;
  path: string;
  runResults: RunResultDoll;
  cols: string[]; // type this
  transformedData: any[]; // type this
}

export type ApiRequestOptions = {
  orgPath?: boolean;
  query?: any;
  timeout?: number;
  orgId?: string;
  isList?: boolean;
  enforceOrder?: boolean;
  debug?: boolean;
};
export enum HttpMethod {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
  PATCH = "PATCH",
}
export type ApiRequest = {
  method: HttpMethod;
  path: string;
  body?: any;
  options: ApiRequestOptions;
  callback?: (response: any) => any;
};

export interface KitInstanceDoll {
  kitId: number | string;
  kitInstanceId: number | string;
  anchorLegoId: number | null;
  order: number | null;
  legoInstances: LegoDoll[];
}

export interface ReceiverButtonData {
  legoPath: string;
  elementPath: string;
  legoName: string;
}

export interface MostRecentRunsUpdate {
  kitId: number;
  legoPath: string;
  runResults: MostRecentRunWrapper;
}
