var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Grid_1_6',_vm._l((_vm.flatSchema2),function(el,i){return _c('InputSwitch',{key:i,staticClass:"tw-col-span-1",class:el.colSpan == 1
        ? 'sm:tw-col-span-1'
        : el.colSpan == 2
        ? 'sm:tw-col-span-2'
        : el.colSpan == 3
        ? 'sm:tw-col-span-3'
        : el.colSpan == 4
        ? 'sm:tw-col-span-4'
        : el.colSpan == 5
        ? 'sm:tw-col-span-5'
        : 'sm:tw-col-span-6',attrs:{"componentId":el.componentId,"value":el.value,"label":el.customLabel ? el.customLabel : el.key,"isRequired":el.defaultAmbiguousInput === null},on:{"input":function($event){return _vm.emitUpdateElement($event, i)}}})}),1)}
var staticRenderFns = []

export { render, staticRenderFns }