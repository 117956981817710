<!-- Tab.vue -->
<template>
  <!-- Making this a v-if could screw up the default indexing behavior in TabWrapper -->
  <div v-show="internalIsActive" data-flank="tab">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Tab",
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number],
      required: false,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    // prop on Tab, but actually controlled in TabWrapper
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      internalIsActive: this.isActive,
    };
  },
  watch: {
    isActive(newVal) {
      this.internalIsActive = newVal;
      if (newVal === true) {
        this.$emit("selected");
      }
    },
    disabled(newDisabled) {
      this.$emit("update:disabled", newDisabled);
    },
  },
  created() {
    this.$on("update:isActive", (isActive) => {
      this.internalIsActive = isActive;
      if (isActive === true) {
        this.$emit("selected");
      }
    });
  },
};
</script>
