var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SidePanel2',{attrs:{"isLoading":_vm.isLoading},on:{"close":function($event){return _vm.$emit('close')}}},[(!_vm.runButtonClicked)?_c('Stack2',{model:{value:(_vm.stack1),callback:function ($$v) {_vm.stack1=$$v},expression:"stack1"}},[_c('template',{slot:"slot0"},[_c('FooterWrapper',{attrs:{"primaryButtonLabel":"Next","scrolls":""},on:{"clickSecondary":function($event){return _vm.$emit('close')},"clickPrimary":_vm.createMultipleRuns}},[_c('HeaderBody',{attrs:{"headerText":("Run " + _vm.legoName + " in bulk")}},[_c('div',{staticClass:"tw-text-gray-500 tw--mt-2 tw-pb-6"},[_vm._v(" Paste in a list of values, separated by new lines, to create variations. Any fields with single values (or empty values) will be repeated for all variations. Any fields that are currently filled in on the main page will populate here. ")]),_c('InputGrid',{attrs:{"flatSchema2":_vm.schema2},on:{"updateElement":_vm.updateElement}})],1)],1)],1),_c('template',{slot:"slot1"},[_c('FooterWrapper',{attrs:{"primaryButtonLabel":"Run","secondaryButtonLabel":"Edit / Cancel","scrolls":""},on:{"clickSecondary":function($event){_vm.stack1 = 0},"clickPrimary":_vm.bulkRun}},[_c('HeaderBody',{attrs:{"headerText":("Review and run " + _vm.legoName),"onStack":""}},[_c('div',[_c('b-table',{staticClass:"tw-w-full tw-overflow-x-auto",attrs:{"checkable":"","mobile-cards":false,"data":_vm.runramList,"checked-rows":_vm.checkedRows},on:{"update:checkedRows":function($event){_vm.checkedRows=$event},"update:checked-rows":function($event){_vm.checkedRows=$event}}},[(_vm.runramList.length > 1)?_c('b-table-column',{attrs:{"label":"Diff"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('div',{staticClass:"tw-flex tw-flex-row"},_vm._l((_vm._odiffOldNewDiffLengths(
                      _vm.runramList[props.index === 0 ? 1 : props.index - 1],
                      props.row
                    )[1]),function(v,k){return _c('div',{key:k,staticClass:"tw-flex tw-flex-row tw-gap-x-2"},[_c('div',{staticClass:"tw-font-bold"},[_vm._v(_vm._s(k)+":")]),_c('div',[_vm._v(" "+_vm._s(v === "" ? '""' : v === null ? "null" : _vm._isDate(v) ? v.toISOString() : v)+" ")])])}),0)]}}],null,false,4286446885)}):_vm._e(),_vm._l((_vm.schema),function(col){return _c('b-table-column',{key:col.key,attrs:{"label":col.key},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(_vm._s(props.row[col.key]))]}}],null,true)})})],2)],1)])],1)],1)],2):(_vm.runButtonClicked)?_c('Stack2',{attrs:{"scrolls":""},model:{value:(_vm.stack2),callback:function ($$v) {_vm.stack2=$$v},expression:"stack2"}},[_c('template',{slot:"slot0"},[_c('HeaderBody',{attrs:{"headerText":("Runs for " + _vm.legoName)}},[_c('div',{staticClass:"tw-flex tw-flex-col tw-gap-y-2"},[_c('div',{staticClass:"tw-text-gray-500 tw--mt-2 tw-pb-6"},[_vm._v(" "+_vm._s(_vm._pyCoalesce(_vm.bulkRunProgress.total, "Your"))+" runs have been submitted. You can exit this window without affecting them. ")]),_c('b-progress',{staticStyle:{"margin-bottom":"-1rem"},attrs:{"max":_vm.bulkRunProgress.total,"show-value":_vm._pytruthy(_vm.bulkRunProgress),"value":_vm._pyCoalesce(_vm.bulkRunProgress.success, 0) +
                _vm._pyCoalesce(_vm.bulkRunProgress.failure, 0) ===
              0
                ? undefined
                : _vm.bulkRunProgress.success + _vm.bulkRunProgress.failure,"type":_vm._pyCoalesce(_vm.bulkRunProgress.success, 0) +
                _vm._pyCoalesce(_vm.bulkRunProgress.failure, 0) ===
              0
                ? undefined
                : _vm.bulkRunProgress.success + _vm.bulkRunProgress.failure ===
                  _vm.bulkRunProgress.total
                ? 'is-success'
                : 'is-primary'}},[_vm._v(" "+_vm._s(_vm.bulkRunProgress.success + _vm.bulkRunProgress.failure + " / " + _vm.bulkRunProgress.total)+" ")]),(_vm._pytruthy(_vm.bulkRunProgress))?_c('div',[_c('a',{on:{"click":function($event){_vm.stack2 = 1;
                _vm.legoRunTableFilters = [
                  ("bulk_parent_id:" + (_vm.bulkRunProgress.parentRunId)),
                  'status:success' ];
                _vm.runDrilldownHeaderText = 'View Successes';}}},[_vm._v("View successes ("+_vm._s(_vm.bulkRunProgress.success)+")")])]):_vm._e(),(_vm._pytruthy(_vm.bulkRunProgress))?_c('div',[_c('a',{on:{"click":function($event){_vm.stack2 = 1;
                _vm.legoRunTableFilters = [
                  ("bulk_parent_id:" + (_vm.bulkRunProgress.parentRunId)),
                  'status:failed' ];
                _vm.runDrilldownHeaderText = 'View Failures';}}},[_vm._v("View failures ("+_vm._s(_vm.bulkRunProgress.failure)+")")])]):_vm._e(),(_vm._pytruthy(_vm.bulkRunProgress))?_c('div',[_c('a',{on:{"click":function($event){_vm.stack2 = 1;
                _vm.legoRunTableFilters = [
                  ("bulk_parent_id:" + (_vm.bulkRunProgress.parentRunId)),
                  'status~:failed',
                  'status~:success' ];
                _vm.runDrilldownHeaderText = 'View Unfinished';}}},[_vm._v("View unfinished ("+_vm._s(_vm.bulkRunProgress.total - _vm.bulkRunProgress.failure - _vm.bulkRunProgress.success)+")")])]):_vm._e(),(_vm._pytruthy(_vm.bulkRunProgress))?_c('div',[_c('a',{on:{"click":function($event){_vm.stack2 = 1;
                _vm.legoRunTableFilters = [
                  ("bulk_parent_id:" + (_vm.bulkRunProgress.parentRunId)) ];
                _vm.runDrilldownHeaderText = 'View All';}}},[_vm._v("View all")])]):_vm._e()],1)])],1),_c('template',{slot:"slot1"},[_c('HeaderBody',{attrs:{"headerText":_vm.runDrilldownHeaderText,"tightDesktop":"","onStack":""}},[_c('LegoRunTable',{attrs:{"showTabs":true,"searchable":false,"showCommandName":true,"onSidePanel":true,"propFilters":_vm.legoRunTableFilters},on:{"useTheseRunrams":_vm.openKitWithRunrams}})],1)],1)],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }