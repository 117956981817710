var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-navbar',{staticClass:"tw-flex-none tw-shadow-md",staticStyle:{"z-index":"35"}},[_c('template',{slot:"brand"},[_c('FlankLogo',{attrs:{"authenticated":_vm.authenticated,"metaEnv":_vm.metaEnv,"activeOrg":_vm.activeOrg}}),(_vm.$route.name !== 'pricing')?[(_vm.isLandingPage)?_c('b-navbar-item',{attrs:{"tag":"div"}},[_c('a',{staticClass:"hover-text tw-text-white hover:tw-text-gray-300 tw-font-bold hover:tw-no-underline",on:{"click":_vm.toRecruiting}},[_vm._v("For Recruiters")])]):_vm._e()]:_vm._e()],2),(_vm.authenticated && _vm.activeOrg && _vm.$route.name !== 'pricing')?_c('template',{slot:"start"},[_c('NavigationButtons',{attrs:{"activeOrg":_vm.activeOrg}})],1):_vm._e(),(_vm.$route.name !== 'pricing')?_c('template',{slot:"end"},[(_vm.isLandingPage)?_c('b-navbar-item',{attrs:{"tag":"div"}},[_c('a',{staticClass:"hover-text tw-text-white hover:tw-text-gray-300 tw-font-bold hover:tw-no-underline",attrs:{"href":"https://btf-org.github.io/flank-docs/","target":"”_blank”"}},[_vm._v("Docs")])]):_vm._e(),(
        !_vm.authenticated &&
        !_vm.$auth.authPluginBeingCreated &&
        _vm.$route.name === 'landingRoute' &&
        _vm.metaEnv !== 'private_ad'
      )?[(_vm.metaEnv === 'saas_internal' || _vm.metaEnv === 'saas_external')?_c('b-navbar-item',{attrs:{"tag":"div"}},[_c('a',{staticClass:"button",on:{"click":_vm.loginAuth0}},[_vm._v("Log in")])]):_vm._e(),(_vm.metaEnv === 'saas_internal' || _vm.metaEnv === 'saas_external')?_c('b-navbar-item',{attrs:{"tag":"div"}},[_c('b-button',{staticClass:"is-primary tw-font-bold",on:{"click":_vm.openCalendly}},[_vm._v("Book a call")])],1):_vm._e()]:_vm._e(),(_vm.authenticated && !_vm.$auth.appSetupInProgress)?[(
          _vm.$route.name !== 'onboardRoute' && _vm.$route.name !== 'loadingRoute'
        )?_c('CreateOrgButtons',{attrs:{"metaEnv":_vm.metaEnv}}):_vm._e(),(_vm.activeOrg != null && _vm.userOrgs.length > 1)?_c('OrgPicker',{attrs:{"activeOrg":_vm.activeOrg}}):_vm._e(),_c('NavBarDropdown',{attrs:{"flankUser":_vm.flankUser,"activeOrg":_vm.activeOrg,"idaas":_vm.idaas}})]:_vm._e()],2):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }