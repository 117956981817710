var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(
      _vm.transformedPayload === null &&
      _vm.runResult.status === 'success' &&
      _vm.frontendState !== 'running'
    )?_c('div',[_c('p',{staticClass:"tw-text-2xl tw-my-4 tw-mx-3"},[_vm._v(" Command ran successfully but did not return an output ")])]):_vm._e(),(_vm.runResult.status === 'success' && _vm.transformedPayload !== null)?_c('div',{staticClass:"tw-h-full"},[(_vm.runResult.errors !== null)?_c('div',{staticClass:"tw-my-6 tw-font-bold tw-h-full tw-overflow-auto"},[_vm._v(" Command successfully ran; however, there were non-fatal errors ")]):_vm._e(),(
        _vm.runResult.payloadDisplayType == 'csv' ||
        _vm.runResult.payloadDisplayType == 'json.table.rowwise' ||
        (_vm.runResult.payloadDisplayType == 'json.multiset.rowwise' &&
          (_vm._oget(_vm._lfirst(_vm.transformedPayload), 'result', null) === null ||
            _vm._oget(_vm._lfirst(_vm.transformedPayload), 'cols', null) === null))
      )?_c('div',{staticClass:"tw-overflow-scroll tw-w-full tw-h-full n tw-shadow-none tw-m-2"},[_c('LegoCardPayloadTable',{attrs:{"transformedPayload":_vm.runResult.payloadDisplayType == 'json.multiset.rowwise'
            ? _vm.transformedPayload
            : _vm.transformedPayload,"legoDoll":_vm.legoDoll,"runResult":_vm.runResult,"propResultCols":_vm.runResult.payloadDisplayType == 'json.multiset.rowwise'
            ? _vm.parseResultCols(_vm.runResult.payload)
            : null},on:{"emitRunForEachRow":function($event){return _vm.$emit('emitRunForEachRow', $event)}}})],1):(
        _vm.runResult.payloadDisplayType == 'json.multiset.rowwise' &&
        _vm._oget(_vm.transformedPayload, 'length', 0) > 1
      )?_c('div',{staticClass:"tw-w-full tw-h-full n tw-shadow-none tw-m-2 tw-flex tw-flex-col"},[_c('b-tabs',{model:{value:(_vm.activeMultisetTab),callback:function ($$v) {_vm.activeMultisetTab=$$v},expression:"activeMultisetTab"}},_vm._l((_vm.transformedPayload),function(resultSet,i){return _c('b-tab-item',{key:i,attrs:{"label":i + 1}})}),1),_c('div',{staticClass:"tw-flex-1 tw-overflow-scroll tw--mt-12"},[_c('LegoCardPayloadTable',{attrs:{"transformedPayload":_vm.transformedPayload[_vm.activeMultisetTab].result,"legoDoll":_vm.legoDoll,"runResult":_vm.runResult,"propResultCols":_vm.transformedPayload[_vm.activeMultisetTab].cols},on:{"emitRunForEachRow":function($event){return _vm.$emit('emitRunForEachRow', $event)}}})],1)],1):(_vm.runResult.payloadDisplayType === 'single_value')?_c('div',{staticClass:"tw-h-full tw-overflow-auto"},[_c('p',{staticClass:"tw-text-2xl tw-my-4 tw-mx-3 tw-font-bold"},[_vm._v(" "+_vm._s(_vm.transformedPayload)+" ")])]):(_vm.runResult.payloadDisplayType === 'string')?_c('div',{staticClass:"tw-h-full tw-overflow-auto"},[_c('p',{staticClass:"tw-my-4 tw-mx-3"},[_vm._v(_vm._s(_vm.transformedPayload))])]):(_vm.runResult.payloadDisplayType === 'json')?_c('div',{staticClass:"tw-h-full tw-overflow-auto"},[_c('JsonViewer',{attrs:{"expanded":true,"value":_vm.transformedPayload,"copyable":true}})],1):(_vm.runResult.payloadDisplayType === 'markdown')?_c('div',{staticClass:"tw-px-2 tw--mt-3 tw-h-full tw-overflow-auto"},[_c('MarkdownDisplay',{attrs:{"markdown":_vm.transformedPayload}})],1):_c('div',{staticClass:"tw-h-full tw-overflow-auto"},[_c('p',{staticClass:"tw-my-4 tw-mx-3"},[_vm._v(_vm._s(_vm.transformedPayload))])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }