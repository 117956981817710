<template>
  <div class="tw-overflow-y-scroll tw-h-full tw-pt-16 tw-px-24">
    <LegoRunTable
      :showTabs="true"
      :searchable="true"
      :showCommandName="true"
      :legoId="legoId"
      @useTheseRunrams="openKitWithRunrams($event)"
    ></LegoRunTable>
  </div>
</template>

<script>
import LegoRunTable from "@/components/runs/LegoRunTable.vue";
import { pathToQueryParams } from "@/shelves/params";
import {
  greenToast,
  redToast,
  copyModal,
} from "@/components/comflonents/buefyActions";
import Vue from "vue";

export default Vue.extend({
  name: "HistoryView",
  components: { LegoRunTable },
  props: [],
  data: function () {
    return {
      legoId: null,
    };
  },
  created() {
    this.legoId = parseInt(this.routeQueryParams.commandId);
  },
  computed: {
    //@ts-ignore
    routeQueryParams() {
      // We need this because the built-in query string parser with
      // vue router does not parse some path strings (e.g. urls) correctly
      try {
        return pathToQueryParams(this.$route.fullPath);
      } catch (e) {
        redToast(
          this,
          "Error parsing parameters from URL. Is there something tricky, like another URL or HTML embedeed inside it?"
        );
        return {};
      }
    },
  },
  methods: {
    openKitWithRunrams(row) {
      const url = this.$router.resolve({
        name: "kitBuilderRoute",
        query: {
          id: row.kitId.toString(),
          injectParamsFrom: row.runId.toString(),
        },
        params: {
          orgId: this.$store.state.activeOrg.orgId,
        },
      }).href;
      window.open(url, "_blank");
    },
  },
});
</script>

<style lang="scss" scoped></style>
